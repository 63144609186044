<template>
  <div class="container">
    <div class="order-block" v-for="(item, index) of list" :key="index" @click="handleGoOrderDetail(item.id)">
      <!-- 订单信息 -->
      <div class='order-info-line'>
        <div class="copy-btn" @click="handleCopy" :id='item.orderNo'>
          <a-icon class='copy-order-no' type="copy" />
          <div class='order-no'>{{ item.orderNo }}</div>
        </div>
        <div class='order-trade-status' :style="{ color: tradeStatusMap[item.tradeStatus].color }">{{ tradeStatusMap[item.tradeStatus].text }}</div>
      </div>

      <!-- 详细信息 -->
      <div class='stone-info-line'>
        <div>
          <img alt="image" class="stone-image"
               v-if="item.orderDetail[0].mainImage" :src='item.orderDetail[0].mainImage + "?x-oss-process=image/resize,w_60,m_lfit"' />
          <div class="stone-title">{{ item.orderDetail[0].title }}</div>
          <div class="stone-count"> ×{{ item.orderDetail[0].count }}</div>
        </div>
        <div class="price">￥{{ item.totalPrice }}</div>
      </div>
    </div>

    <!-- 加载提示 -->
    <loading :is-loading="isLoading" :is-all-loaded="isAllLoaded"></loading>
  </div>
</template>

<script>
import Loading from '@/components/loading/loading'
import page from '@/mixins/page'
import Clipboard from 'clipboard'
import status from '@/mixins/status'

export default {
  name: 'OrderList',

  components: { Loading },

  mixins: [page, status],

  data () {
    return {
    }
  },

  computed: {
    // 获取列表的参数
    args () {
      return {
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  beforeMount () {
    // 监听滚动到底的事件
    window.addEventListener('scroll', this.onReachBottom(this.getOrderList))
    this.getOrderList()
  },

  beforeDestroy () {
    removeEventListener('scroll', this.onReachBottom(this.getOrderList))
  },

  methods: {
    // 获取数据
    getOrderList () {
      this.api.getOrderListByUser(this.args).then(res => {
        const list = res.data.getOrderListByUser.data
        this.list = this.list.concat(list)
        this.total = res.data.getOrderListByUser.total
        this.isLoading = false
        this.isAllLoaded = this.list.length >= this.total
      }).catch(() => {
        this.isLoading = false
      })
    },

    // 复制订单号
    handleCopy () {
      const clipboard = new Clipboard('.copy-btn', {
        text: (res) => {
          return res.textContent
        }
      })

      clipboard.on('success', () => {
        this.$message.success('成功复制订单号')
        clipboard.destroy()
      })

      clipboard.on('error', () => {
        this.$message.error('复制订单号失败')
        clipboard.destroy()
      })
    },

    // 跳转订单详情页
    handleGoOrderDetail (id) {
      this.$router.push({
        path: `/order/${id}`
      })
    }
  }
}
</script>

<style scoped>
.container {
  min-width: 600px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  margin-top: 20px;
}

.order-block {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
}
.order-block:hover {
  box-shadow: 0 0 5px 1px rgba(111, 111, 111, 0.1);
}

.order-info-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f5f5f5;
  padding: 10px 25px;
}
.order-info-line > div:first-child {
  display: flex;
  align-items: center;
}
.copy-order-no {
  font-size: 12px;
}
.order-no {
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
}
.order-trade-status {
  font-size: 16px;
}

.stone-info-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
}
.stone-info-line > div:first-child {
  display: flex;
  align-items: center;
}
.stone-image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.stone-title {
  margin-left: 15px;
  font-size: 18px;
}
.stone-count {
  font-size: 18px;
  color: #333;
}
.price {
  color: var(--red-color);
  font-size: 20px;
  font-weight: bolder;
}
</style>
